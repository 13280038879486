import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IEmail } from '../../custom/models/email';
import { EmailService } from '../../custom/services/email.service';

declare var require: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  htmlCorpoEmailContato = require("raw-loader!../../../../assets/html/email/email-contact.html");

  private email: IEmail = <IEmail>{};

  formContact: FormGroup;

  constructor(
                private objEmail: EmailService,
                private fb: FormBuilder,
            ) {

        this.formContact = fb.group({
            "nome": ["", Validators.required],
            "email": ["", Validators.required],
            "assunto": ["", Validators.required],
            "telefone": [""],
            "mensagem": ["", Validators.required],
          });

    }

  ngOnInit() {
  }

  onEnviaEmailContato(){

    this.email.Destinatario = "contato@performatic.com.br";
    this.email.Assunto = "#Contato Assunto: " + this.formContact.controls["assunto"].value;

    let strCorpoAux: string = this.htmlCorpoEmailContato.default;

    strCorpoAux = strCorpoAux.replace("{Assunto}", this.formContact.controls["assunto"].value);
    strCorpoAux = strCorpoAux.replace("{Message}", this.formContact.controls["mensagem"].value);
    strCorpoAux = strCorpoAux.replace("{Nome}", this.formContact.controls["nome"].value);
    strCorpoAux = strCorpoAux.replace("{Email}", this.formContact.controls["email"].value);
    strCorpoAux = strCorpoAux.replace("{Telefone}", this.formContact.controls["telefone"].value);

    this.email.CorpoEmail = strCorpoAux;

    let result = this.objEmail.enviaEmail(this.email);

    console.log(result);
    
  }

}
