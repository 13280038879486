
import { Injectable } from '@angular/core';

import { map, catchError } from 'rxjs/operators';

import { SharedService} from './shared.service';

import { HttpClient } from '@angular/common/http';
import { IEmail } from '../models/email';

import emailConfig from '../models/emailConfig';


//const nodemailer = require("nodemailer");

@Injectable()
export class EmailService {

  constructor(private http: HttpClient,
   private sharedService: SharedService){}

   //apontando para WEB API + Controller
   url = this.sharedService.getMyApiUrl() + "api/Email/";


    //post - Envio basico
    enviaEmail(registro: IEmail) {
      // return this.http.post(this.url, registro).pipe(
      //                catchError(this.sharedService.handleErrorObservable));

          let mailOptions = {
              from: "contato@performatic.com.br",
              to: registro.Destinatario,
              subject: registro.Assunto,
              html: registro.CorpoEmail
          };
          
        //   const transporter = nodemailer.createTransport({
        //     host: emailConfig.host,
        //     port: emailConfig.port,
        //     secure: false,
        //     auth: {
        //         user: emailConfig.user,
        //         pass: emailConfig.password
        //     },
        //     tls: { rejectUnauthorized: false }
        // });

        
        console.log(mailOptions);

        // transporter.sendMail(mailOptions, function (error, info) {
        //     if (error) {
        //         return error;
        //     } else {
        //         return "E-mail enviado com sucesso!";
        //     }
        // });



    }
}
