import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-fourteen',
  templateUrl: './home-fourteen.component.html',
  styleUrls: ['./home-fourteen.component.scss']
})
export class HomeFourteenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
