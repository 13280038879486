import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cta-two',
  templateUrl: './cta-two.component.html',
  styleUrls: ['./cta-two.component.scss']
})
export class CtaTwoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
