import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-seventeen',
  templateUrl: './home-seventeen.component.html',
  styleUrls: ['./home-seventeen.component.scss']
})
export class HomeSeventeenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
