import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-features',
    templateUrl: './features.component.html',
    styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: 'Nossos Recursos',
            title: 'Estamos sempre buscando superar as expectativas de nossos clientes',
            paragraphText: 'Mantendo o contato próximo, nossas equipes estão sempre atentas a entender as expectativas de nossos clientes e superá-las é nosso forte.'
        }
    ]

    singleFeatures: singleFeaturesContent[] = [
        {
            icon: 'icofont-edit',
            title: 'Criação de Web Sites',
            paragraphText: 'Temos um portfólio grande de templates prontos para atender a diversas necessidades, independente se precisa divulgar produtos ou serviços'
        },
        {
            icon: 'icofont-gears',
            title: 'Desenvolvimento de Sistemas',
            paragraphText: 'Nossos sistemas de gestão são customizaveis para aderir melhor ao seu negócio, precisamos apenas te ouvir para entender suas necessidades'
            // Nossas equipes de Analistas e Desenvolvedores estão sempre atualizados nas linguagens mais atuais
        },
        {
            icon: 'icofont-pie-chart',
            title: 'BI (Business Inteligence)',
            paragraphText: 'Os melhores gráficos para tornar sua informação simples, ampliando a acertividade nas tomadas de decisões, independente do nível de Gestão'
        }
    ]

}

class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}

class singleFeaturesContent {
    icon : string;
    title : string;
    paragraphText : string;
}
